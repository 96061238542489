body {
  background-color: #202125 !important;
}

.VapeClaim_Header {
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
}

.VapeClaim_Header .byoimg {
  margin-left: 20px;
}

.VapeClaim_Footer {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: 'ChakraPetch', cursive;
  color: white;
}

.VapeClaim_Notice {
    color: red;
    font-size: 1rem;
    text-align: center;
  }
  
  .VapeClaim_Notice_Res {
    color: white;
    font-size: 1rem;
    padding: 20px;
    margin-top: 40px;
    line-height: 25px;
    text-align: center;
  }
  
  .VapeClaim_Notice_Res p {
    word-break: break-word;
  }
  
  .VapeClaim {
    text-align: center;
  }
  
  .VapeClaim_MainSection {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .VapeClaim_Input {
    margin: 0 auto;
    margin-top: 50px;
    width: 500px;
    height: 35px;
    font-family: 'ChakraPetch', cursive;
    text-align: center;
  }
  
  .VapeClaim_ActionButton {
    background: white;
    border: none;
    color: red;
    font-family: 'ChakraPetch', cursive;
    padding: 20px;
    border-radius: 5px;
    margin-top: 40px;
    transition: 1s;
    max-width: 300px;
    cursor: pointer;
  }
  
  @media only screen and (max-width: 600px) {
    .VapeClaim_MainSection {
      width: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .VapeClaim_Notice {
      max-width: 90%;
      line-height: 20px;
    }
  
    .VapeClaim_Input {
      max-width: 90%;
      font-size: 0.8rem;
    }
  
    .VapeClaim_Notice_Res {
      font-size: 0.7rem;
      max-width: 90%;
    }
  }
  